<template>
  <div class="app-container">
    <div class="filter-container" style="min-height: auto;width: 100%">
      <el-form :inline="true" :model="queryForm" ref="queryForm" class="form-inline tz"  style="width: 100%">
        <el-row style="width: 100%">
          <el-form-item label="开票日期" label-width="15%"  style="width: 36%" class="range-query">
            <!--          <el-date-picker
                          v-model="date"
                          :editable = false
                          type="daterange"
                          align="left"
                          unlink-panels
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          :default-time="['00:00:00', '23:59:59']"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          :picker-options="pickerOptions">
                      </el-date-picker>-->
            <el-date-picker
                v-model="startDate"
                type="date"
                placeholder="开始日期"
                :picker-options="startDatePicker"
                style="width: 45%"
                format="yyyy年MM月dd日"
                value-format="yyyy-MM-dd"
            />
            至
            <el-date-picker
                v-model="endDate"
                type="date"
                placeholder="结束日期"
                :picker-options="endDatePicker"
                style="width: 45%"
                format="yyyy年MM月dd日"
                value-format="yyyy-MM-dd"
            />
          </el-form-item>
          <el-form-item label="购买方名称" prop="textSearch" style="width: 19%" label-width="35%" class="single_query">
            <el-input placeholder="请输入查询条件" v-model="queryForm.buyerName" maxlength="50" class="filter-item" @keyup.enter.native="handleQuery"/>
          </el-form-item>
          <el-form-item label="年度" prop="comboSearch" style="width: 19%" label-width="35%" class="single_query">
            <el-select v-model="queryForm.year" placeholder="请选择" clearable  class="filter-item" @change="yearSelect">
              <el-option v-for="item in yearOptions" :key="item.value" :label="item.key" :value="item.value"/>
            </el-select>
          </el-form-item>
          <el-form-item label="月度" prop="comboSearch" style="width: 19%" label-width="35%" class="single_query">
            <el-select v-model="queryForm.month" placeholder="请选择" clearable  class="filter-item">
              <el-option v-for="item in monthOptions" :key="item.value" :label="item.key" :value="item.value"/>
            </el-select>
          </el-form-item>
        </el-row>
        <el-row style="width: 100%">
          <el-form-item label="发票号码" prop="textSearch" label-width="15%" style="width: 36%" class="range-query removeControl">
            <el-input placeholder="请输入查询条件" v-model="queryForm.invoiceNoStart" oninput="if(value.length>25)value=value.slice(0,25)" type='number' class="filter-item" @keyup.enter.native="handleQuery"/>
            至
            <el-input placeholder="请输入查询条件" v-model="queryForm.invoiceNoEnd" oninput="if(value.length>25)value=value.slice(0,25)" type='number' class="filter-item" @keyup.enter.native="handleQuery"/>
          </el-form-item>
          <el-form-item label="发票代码" prop="textSearch" style="width: 19%" label-width="35%" class="single_query removeControl">
            <el-input placeholder="请输入查询条件" v-model="queryForm.invoiceCode" oninput="if(value.length>25)value=value.slice(0,25)" type='number' class="filter-item" @keyup.enter.native="handleQuery"/>
          </el-form-item>
          <el-form-item label="开票金额" prop="textSearch" style="width: 19%" label-width="35%" class="single_query">
            <el-input placeholder="请输入查询条件" v-model="queryForm.invoiceAmount" maxlength="25" class="filter-item" @keyup.enter.native="handleQuery"/>
          </el-form-item>
          <el-form-item style="width: 19%" class="button_query">
            <el-button v-waves class="filter-item" style="margin-left: 20%;" type="primary" icon="el-icon-search" @click="handleQuery" v-auth='"einvWrite:lookup"'>搜索</el-button>
            <el-button v-waves class="filter-item" style="margin-left: 10px;" type="primary" icon="el-icon-refresh" @click="handleReset()" v-auth='"einvWrite:lookup"'>重置</el-button>
          </el-form-item>
        </el-row>
      </el-form>
    </div>

    <div class="storage_table">
      <el-button type="primary" class="download" @click="batchDownLoad" :disabled='multipleSelection.length == 0' v-auth='"einvWrite:download"'>下载</el-button>
      <div class="breadcrumb">
        <div class="location">
          当前位置：<i class="iconfont iconicon-15"></i>
        </div>
        <el-breadcrumb class="breadcrumbItem" separator-class="el-icon-arrow-right">
          <!-- <el-breadcrumb-item :to="{ path: '/' }">增值税销项电子发票目录</el-breadcrumb-item> -->
          <el-breadcrumb-item v-for="(item,index) in breadCrumbItems" :key="index"><span @click='breadCrumbItemClick(item)'>{{item.name}}</span></el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div v-if="breadCrumbItems.length < 3" class="comps_table" v-loading='isLoading'>
        <ul v-if="hasData">
          <el-checkbox-group v-model="multipleSelection" @change="handleCheckedChange" class="checkboxGroup">
            <li v-for="(item,index) in tableData" :key="index" class="folder" @dblclick="gotoChildren(item)">
              <el-checkbox :label="item.value" :key="item.value" class="checkbox">
                <i class="iconfont iconicon-15"></i>
                <p>{{item.name}}</p>
              </el-checkbox>
            </li>
          </el-checkbox-group>
        </ul>
        <nodata v-else message="暂无数据" class="no_data" v-loading="isLoading" />
      </div>
      <div class="comps_table" v-else>
        <comps-table :tableData="tableData" :tableHeader="tableHeader" v-loading='isLoading' :hideSinglePage='true' class="comp_table"
         v-if="hasData" :selectionType='true' @multipleSelection='selection' :total='total' :pageSize='queryForm.rows'
         @current-events="handleCurrentChange" @size-events="changeSize" :pageSizeList='pageSizeList' :page='queryForm.page' height="84%">
        </comps-table>
        <nodata v-else message="暂无数据" class="no_data" v-loading="isLoading" />
      </div>
    </div>

    <!--<del-dialog :visible="delDialog" :cancelDialog="cancelDialog" :confirmDialog="confirmDialog" :isdeling="isdeling" :config="config">
      <div class="answer">亲，确认要删除吗？</div>
    </del-dialog>-->
    <preview-dialog  ref="pdf"/>
    <!-- <pdf-dialog :visible='pdfVis' :cancelDialog='pdfCancel' :title="pdfName" ref="pdf"/>
    <ofd-dialog :visible='ofdVis' :cancelDialog='ofdCancel' :title="ofdName" ref="ofd"/> -->
    <download-dialog :visible='downloadVis'  @selectType="selectType" @selectAddType="selectAddTypeHandle" :isdeling='isdeling' :confirmDialog='confirmDownLoad' :cancelDialog='cancelDownLoad' ref="download"/>

  </div>
</template>

<script>
  import delDialog from '@/components/Dialog'
  import compsTable from "@/components/table/table";
  // import downloadDialog from './dialog/download'
  import downloadDialog from '@/components/downloadInvoice' //下载弹窗引入
  import previewDialog from '@/components/previewDialog'
  // import { getInvoicePicture }  from '@/api/eInv'
  // import pdfDialog from './dialog/showPdf'
  // import ofdDialog from './dialog/showOdf'
  import axios from 'axios'
  export default {
    components: { delDialog ,compsTable , downloadDialog,previewDialog},
    data() {
      return {
        queryForm: {
          type: '2', //销项
          invoiceTimeStart: '',
          invoiceTimeEnd: '',
          buyerName: '',
          year: '',
          month:'',
          invoiceNoStart: '',
          invoiceNoEnd: '',
          invoiceCode: '',
          invoiceAmount:'',
          page: 1,
          rows: 20
        },
        years: [],
        months: [],
        ids: [],
        total: 0,
        pageSizeList: [20, 50, 100, 200],
        startDate:undefined,
        endDate:undefined,
        startDatePicker: this.beginDate(),
        endDatePicker: this.processDate(),
        invoiceTypeOptions:[{'key': '电子普票','value': '1'},{'key': '电子专票','value': '2'}],
        invoiceStatusOptions:[{'key': '正数','value': '1'},{'key': '负数','value': '2'}],
        yearOptions: [{'key': '全部','value':''}],
        monthOptions: [{'key': '全部','value':''}],
        isLoading: false,
        hasData: true,
        tableHeader: [
          { prop: "num", label: "NO.", center: 'center', width:'80%'},
          { prop: "year", label: "年度", center: 'center', width:'140%'},
          { prop: "month", label: "月份", center: 'center', width:'140%'},
          { prop: "fileName", label: "文件名", center: 'center', width:'300%',
            render: (h, data) => {
              return (
                  <span>
                    <el-button class='button'  type="text" onClick={() => { this.getFileUrl(data.row);}}>
                      {data.row.fileName}
                    </el-button>
                  </span>
              );
            },},
          { prop: "invoiceTime", label: "开票日期", center: 'center'},
          { prop: "invoiceAmount", label: "开票金额", center: 'center'},
          { prop: "invoiceType", label: "发票类型", center: 'center',
            formatter: (row) => {
              const typeMap = {
                '1': '电子普票',
                '2': '电子专票'
              }
              return typeMap[row.invoiceType];
            },
          },
          { prop: "buyerName", label: "购买方名称", center: 'center', width:'200%'},
        ],
        tableData: [],
        yearList: {},
        isdeling: false,
        config: {
          top: '20vh',
          width: '16%',
          title: '提示',
          btnTxt: ["确定", "取消"]
        },
        delDialog: false,
        downloadVis: false,
        downloadType: '1',
        selectAddType:false,
        pdfVis: false,
        ofdVis: false,
        promptVisible : false,
        pdfName:'',
        ofdName:'',
        breadCrumbItems:[{name:'增值税销项电子发票目录',page:'1'}],
        multipleSelection: [],
        itemId:'null',
        imgUrl: undefined,
      }
    },
    methods: {
      beginDate(){
        const self = this
        return {
          disabledDate(time){
            if (self.endDate) {
              //如果结束时间不为空，则小于结束时间
              //时区转换
              var ntime = new Date(time.getTime())
              ntime.setHours(ntime.getHours() + 8)
              return new Date(self.endDate).getTime() < ntime.getTime()
            } else {
            }
          }
        }
      },
      processDate() {
        const  self = this
        return {
          disabledDate(time) {
            if (self.startDate) {  //如果开始时间不为空，则结束时间大于开始时间
              //时区转换
              var ntime = new Date(time.getTime())
              ntime.setHours(ntime.getHours() + 8)
              return new Date(self.startDate).getTime() > ntime.getTime()
            } else {
            }
          }
        }
      },
      handleQuery(){
        this.queryForm.page = 1
        this.getYearAndMonth()
      },
      //重置
      handleReset() {
        this.startDate = undefined
        this.endDate = undefined
        this.queryForm =  {
              type: '2', //销项
              invoiceTimeStart: '',
              invoiceTimeEnd: '',
              buyerName: '',
              year: '',
              month:'',
              invoiceNoStart: '',
              invoiceNoEnd: '',
              invoiceCode: '',
              page: 1,
              rows: 20
        };
        this.$refs.queryForm.resetFields()
        this.getYearAndMonth()
      },
      beforeQuery(){
        if(this.queryForm.invoiceNoStart !== '' && this.queryForm.invoiceNoEnd !== ''
            && Number(this.queryForm.invoiceNoStart) > Number(this.queryForm.invoiceNoEnd)){
          einvAlert.warning("提示","起始发票号码不能大于结束发票号码")
          return false;
        }
        if (this.startDate !== undefined && this.startDate !== null) {
          this.queryForm.invoiceTimeStart = this.startDate + ' 00:00:00'
        }else{
          this.queryForm.invoiceTimeStart = ''
        }
        if (this.endDate !== undefined && this.endDate !== null) {
          this.queryForm.invoiceTimeEnd =this.endDate + ' 23:59:59'
        }else{
          this.queryForm.invoiceTimeEnd = ''
        }
        return true;
      },
      //
      getList() {
        let _this = this ;
        if(!_this.beforeQuery()){
          return;
        }
        _this.isLoading = true;
        _this.beforeQuery()
        var year = _this.years[0].value;
        var month = _this.months[0].value;
        this.$store.dispatch("FindEInv",
            {
              agencyCompanyId: this.getToken('agencyCompanyId'),
              companyId: _this.getToken('companyId'),
              userId: _this.getToken('userId'),
              type: _this.type,
              yearList: year,
              monthList: month,
              ..._this.queryForm,
            }
        ).then(res => {
          if (res.success && res.data.length > 0) {
            _this.isLoading = false;
            _this.hasData=true;
            _this.tableData = res.data;
            _this.total = res.totalCount
            console.dir(this.years)
            for(var i=0; i<this.tableData.length;i++){
              var invoice = this.tableData[i];
              _this.tableData[i].year = year + '年'
              _this.tableData[i].month = month + '月'
              _this.tableData[i].num = i+1;
              _this.tableData[i].invoiceTime = _this.tableData[i].invoiceTime.slice(0,10)
              const yyMMdd = _this.tableData[i].invoiceTime.replaceAll("-", "");
              const suffix = _this.tableData[i].fileName.substring(_this.tableData[i].fileName.lastIndexOf("."));
              if(_this.tableData[i].invoiceCode == null || _this.tableData[i].invoiceCode == undefined ){
                _this.tableData[i].fileName = _this.tableData[i].invoiceNo +"_"+ yyMMdd+suffix
              }else{
                _this.tableData[i].fileName = _this.tableData[i].invoiceCode +"_"+ _this.tableData[i].invoiceNo +"_"+ yyMMdd+suffix
              }
            }
          } else {
            this.hasData=false;
            this.isLoading = false;
          }
        }).catch(err => {
          this.isLoading = false;
          //einvAlert.error("提示",err)
        })
      },
      // 切换表格分页
      changeSize(size) {
        this.queryForm.rows = size
        this.getList();
      },
      handleCurrentChange(current){
        this.queryForm.page = current
        this.getList();
      },
      selection(val){
        this.multipleSelection = val.map(e=>{return e.id});
        this.ids = this.multipleSelection
      },
      handleCheckedChange(value) {
        this.multipleSelection = value
      },
      //查看发票
      // getFileUrl(item){
      //   //var date = new Date(item.invoiceTime);
      //   var yyMMdd = item.invoiceTime.slice(0,10).replaceAll("-","");
      //   var _this = this
      //   this.$store.dispatch("GetFileUrl", {
      //     id: item.id
      //   }).then(async res=>{
      //     if (res.success) {
      //       if(item.saveType === 1){
      //         _this.$refs.pdf.fileUrl = res.data+"?token="+sessionStorage.getItem('Authorization');
      //         _this.$refs.pdf.year = this.years[0].value
      //         _this.$refs.pdf.month = this.months[0].value
      //         _this.pdfName = item.invoiceCode +"_"+ item.invoiceNo +"_"+ yyMMdd+".pdf"
      //         _this.showPdf()
      //       }else if(item.saveType === 2){
      //         _this.$refs.ofd.fileUrl = res.data+"?token="+sessionStorage.getItem('Authorization');
      //         _this.$refs.ofd.year = this.years[0].value
      //         _this.$refs.ofd.month = this.months[0].value
      //         _this.$refs.ofd.show()
      //         _this.ofdName = item.invoiceCode +"_"+ item.invoiceNo +"_"+ yyMMdd+".ofd"
      //         _this.showOfd()
      //       }else {
      //         _this.showJpg()
      //       }
      //     } else {
      //       einvAlert.error("提示",res.msg)
      //     }
      //   })
      // },
            // 发票预览
      // getFileUrl(item){
      //   let data = {
      //     companyId:this.getToken('companyId'),
      //     invoiceCode:item.invoiceCode,
      //     invoiceNo:item.invoiceNo,
      //     einvToken:this.getToken('Authorization'),
      //   }
      //   this.itemId =item.id;
      //   this.pdfName =item.fileName;
      //   getInvoicePicture(data)
      //   .then(res => {
      //     const dataInfo = res.data[0]
      //     console.log(dataInfo,'dataInfo')
      //     this.imgUrl = 'data:image/jpeg;base64,' + dataInfo
      //     this.showPdf()
      //   }).catch(error => {
      //     einvAlert.error("预览失败",error.msg||error.message)
      //   })
      // },
      //点击预览获取文件
        getFileUrl(item){
          this.$refs.pdf.getFileUrl(item,false)
        },
      imports(){
        this.importVis = true;
      },
      importCancel(){
        this.importVis = false;
      },
      showPdf(){
        this.pdfVis = true;
      },
      pdfCancel(){
        this.pdfVis = false;
      },
      showOfd(){
        this.ofdVis = true;
      },
      ofdCancel(){
        this.ofdVis = false;
      },
      batchDownLoad(){
        this.downloadVis = true;
      },
      cancelDownLoad(){
        this.downloadVis = false;
      },
      showTips(){
        this.promptVisible = true;
      },
      tipsCancel(){
        this.promptVisible = false;
      },
      // 子集页面
      gotoChildren(item) {
        if(this.breadCrumbItems.length < 2){
          this.tableData = item.months
          this.years =[]
          this.years.push(item)
        }else if(this.breadCrumbItems.length === 2){
          this.months =[]
          this.months.push(item)
          this.getList(); //查询操作
        }
        if (this.breadCrumbItems.length === 0) {//设定 面包屑集合
          this.breadCrumbItems = [{ name: '增值税销项电子发票目录', page: 1, }, { ...item, page: 1 },];
        } else {
          this.breadCrumbItems = [...this.breadCrumbItems, { ...item, page: 1 }];
        }
        this.multipleSelection = [] ; //清除选中行提示
      },

      //面包屑操作
      breadCrumbItemClick(archiveItem){
        const indexofCurrentItem = this.breadCrumbItems?.indexOf(archiveItem);
        if (indexofCurrentItem === 0) { //回到首级菜单
          this.breadCrumbItems = [{ name: '增值税销项电子发票目录', page: 1, }];
          this.multipleSelection = [] ; //清除选中行提示
          this.ids = "";
          this.tableData = this.yearList;
        } else if (indexofCurrentItem === this.breadCrumbItems?.length - 1) {
          //本级
          return;
        } else {
          this.breadCrumbItems = this.breadCrumbItems.slice(0, indexofCurrentItem + 1); //slice分割 当前所剩集合
          this.multipleSelection = [] ; //清除选中行提示
          this.ids = "";
          this.tableData = archiveItem.months;
        }
        // console.log(this.breadCrumbItems , '12321' , indexofCurrentItem);
      },
      //获取年-月
      getYearAndMonth(){
        if(!this.beforeQuery()){
          return;
        }
        if(this.breadCrumbItems.length !== 1){
          this.breadCrumbItemClick({name:'增值税销项电子发票目录',page:'1'})
        }
        this.isLoading = true;
        this.$store.dispatch("FindYearAndMonth",
            {
              companyId: this.getToken('companyId'),
              userId: this.getToken('userId'),
              ...this.queryForm
            }
        ).then(res => {
          if (res.success && res.data != null) {
            this.isLoading = false;
            this.hasData=true;
            this.yearList = res.data.map(e=>{
              return {
                name : e.year+'年',
                value : e.year,
                months : e.months.map(p=>{
                  return {
                    name: p + '月',
                    value : p,
                  }
                })
              }
            })
            this.tableData = this.yearList
            this.yearOptions = res.data.map(e=>{
              return {
                key : e.year+'年',
                value : e.year,
                months : e.months.map(p=>{
                  return {
                    key: p + '月',
                    value: p
                  }
                })
              }
            })
            this.yearOptions.unshift({'key':'全部','value':''})
            this.yearOptions.forEach(e => {
              if(e.months != undefined){
                e.months.unshift({'key':'全部','value':''})
              }
            })
          } else {
            this.hasData=false;
            this.isLoading = false;
            //einvAlert.error("提示",res.msg)
          }
        }).catch(err => {
          this.isLoading = false;
        //  einvAlert.error("提示",err)
        })
      },
      selectType(value){
        this.downloadType = value
      },
      selectAddTypeHandle(value){
        this.selectAddType = value
          console.log('切换是否需要销售章');
      },
      //批量下载
      confirmDownLoad(){
        this.isdeling = true;
        var lyear='' ,lmonth='',lids=''
        if(this.breadCrumbItems.length === 1){
          for(var i=0; i<this.multipleSelection.length;i++){
            if(this.multipleSelection[i] !== undefined){
              lyear += this.multipleSelection[i] +','
            }
          }
          lyear.slice(0,lyear.lastIndexOf(','))
        }else if(this.breadCrumbItems.length === 2){
          for(var j=0; j<this.multipleSelection.length;j++){
            if(this.multipleSelection[j] !== undefined){
              lmonth += this.multipleSelection[j] +','
              lyear = this.years[0].value;
            }
          }
          lmonth.slice(0,lmonth.lastIndexOf(','))
        }
        for(var l=0; l<this.ids.length;l++){
          if(this.ids[l] != undefined){
            lids += this.ids[l] +','
          }
        }
        lids.slice(0,lids.lastIndexOf(','))
          axios({
            method: 'get',
            url: '/api/einvoice/einv/batchDownloadAddSeal',
            responseType: 'blob',
            params: {
              companyId: this.getToken('companyId'),
              companyName: this.getToken('nowCompany'),
              userId: this.getToken('userId'),
              // ids: this.ids,
              // years: this.years,
              // months: this.months,
              yearList: lyear,
              monthList: lmonth,
              idList: lids,
              downType: this.downloadType,
              addSeal: this.selectAddType, //是否添加销售章
              ...this.queryForm
            },
            headers: {
              'Content-Type': "application/json;charset=utf-8",
              'Authorization': this.getCookies('Authorization'),
              'group' :process.env.VUE_APP_GROUP
            }
          }).then((res) => {
            let data = res.data
            let url = window.URL.createObjectURL(new Blob([data], { type: "application/zip" }))
            let link = document.createElement('a')
            link.style.display = 'none'
            link.href = url;
            var now = window.formatDate(new Date(), 'yyyyMMddhhmmss')
            link.setAttribute('download', '销项电子发票' + now + '.zip')
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            this.isdeling = false
            this.downloadVis=false
        }).catch(err => {
          this.isLoading = false;
         // einvAlert.error("提示",err)
        })
      },
      yearSelect(val){
        for(var i=0; i < this.yearOptions.length;i++){
          if(val === this.yearOptions[i].value){
            this.monthOptions = this.yearOptions[i].months
            this.queryForm.month = ''
          }
        }

      }
    },
    mounted() {
      this.getYearAndMonth()
    },
    watch:{
      queryForm:{
        deep: true,
        immediate:true,
        handler(){
          console.log('1221');
          // 去掉输入框中首位空格
          let keys = Object.keys(this.queryForm);
          keys.forEach(item =>{
            if(typeof(this.queryForm[item])=='string'){
              this.queryForm[item] = this.queryForm[item].trim()
            }
          })
        }
      }
    }
  }
</script>
<style lang="less">
.single_query{
  .el-form-item__content {
    width: 60% !important;
  }
}
.range-query{
  .el-form-item__content {
    width: 85% !important;
  }
  .filter-item{
    width: 45%;
  }
}
.button_query{
  .el-form-item__content {
    width: 100% !important;
  }
}
</style>

<style lang="less" scoped>
  .tz {
    .el-form-item{
      margin-right: 1.2rem !important;
    }
  }
  nav {
    height: auto !important;
    display: block !important;
    padding: 12px 0.94rem !important;
  }
  .tips{
    margin-top: 12px;
  }
  .filter-container{
    /deep/label{
      width: 100px;
    }
  }
  .storage_table {
    min-height: calc(100% - 160px);
    padding: 18px 18px;
    background-color: #fff;
    .comps_table{
      padding: 15px;
      border: 1px solid #D1D1D1;
      height: calc(100% - 100px);
      .no_data {
        height: 200px;
      }
      .buttons{
        margin-right: 10px !important;
      }
      ul{
        display: flex;
        padding-left: 32px;
        // .checkboxGroup{
        //   display: flex;
        // }
      }
      .folder{
        list-style: none;
        text-align: center;
        margin-right: 8px;
        float: left;
        .checkbox{
          width: 6.6rem;
          height: 6.6rem;
          line-height: 196px;
          border: 1px solid transparent;
          /deep/ .el-checkbox__input{
            display: none !important;
          }
          /deep/ .el-checkbox__label {
            display: inline-block;
            padding-left: 0 !important;
          }
          i{
            color: #FFCB23;
            font-size: 70px;
          }
          p{
            padding: 0;
            margin: 0;
            line-height: 40px;
            font-size: 16px;
          }
        }
        .is-checked{
          background: #E0EFFF !important;
          border: 1px solid #409EFF;
        }
      }
      .checkbox:hover{
        background: #E0EFFF;
        border: 1px solid #409EFF;
      }
    }
    .download{
      min-width: 4.69rem;
      margin-bottom: 12px;
    }
    .breadcrumb{
      display: flex;
      height: 50px;
      line-height: 50px;
      background: #EDF0FF;
      margin-bottom: 1px;
      .location{
        padding-left: 20px;
        font-size: 14px;
        i{
          color: #FFCB23;
          margin-right: 10px;
        }
      }
      .breadcrumbItem{
        line-height: 52px;
        cursor: pointer;
      }
    }
  }
  .answer {
    text-align: center;
    padding: 24px 0 14px;
    font-size: 16px;
  }
/*  .filter-container{
    /deep/ label{
      width: 100px;
    }
  }*/
  /deep/.removeControl {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    input[type="number"]{
      -moz-appearance: textfield;
    }
  }
</style>
